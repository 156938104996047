nav {
  display: flex;
  height: auto;
  width: 100%;
  align-items: center;
  background-color: var(--primary-color);
  font-size: 1.7rem;
  justify-content: space-between;
  padding: 25px 40px 25px 50px;
  position: fixed;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
  z-index: 200;
}

.logo {
  color: var(--text-color);
  cursor: pointer;
}

nav ul {
  display: flex;
  gap: 2rem;
  list-style: none;
  flex: 1;
  justify-content: right;
}

nav ul a {
  color: var(--text-color);
  font-weight: 600;
  text-decoration: none;
  transition: all 0.23s;
}

nav ul a:hover {
  color: var(--hover-color);
}

nav ul a button {
  background-color: transparent;
  height: auto;
}

nav i {
  font-size: 25px;
  padding: 7px 0 0 20px;
  cursor: pointer;
  color: var(--text-color);
}

@media (max-width: 400px) {
  nav {
    padding: 25px 30px 25px 30px;
  }
}

@media (max-width: 900px) {
  nav ul {
    display: none;
  }
}
