#project {
  background-color: var(--secondary-color);
  padding: 13rem 0;
}

.project-content {
  display: flex;
  flex-direction: column;
}

.project-content p {
  color: var(--hover-color);
  font-size: 1.7rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.project-content h3 {
  color: var(--text-color);
  font-size: 2.5rem;
  margin-bottom: 4rem;
}

.projects-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
}

.pro-card {
  flex-shrink: 0;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 450px;
  height: 490px;
  background-color: var(--primary-color);
  margin-bottom: 4rem;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.pro-img {
  width: 450px;
  height: 250px;
  background-color: #76767633;
  overflow: hidden;
  cursor: pointer;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.pro-img img {
  width: 100%;
  height: auto;
}

.pro-img:hover img {
  transform: translate(0, -74.5%);
  transition: transform 10s ease-in-out 0s;
}

.pro-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.25rem;
  gap: 1.5rem;
}

.pro-text h3 {
  margin-bottom: 0;
}

.pro-text h4 {
  text-align: start;
  font-size: 1.4rem;
  color: var(--secondary-text-color);
  font-weight: normal;
}

.stack {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.links {
  display: flex;
  gap: 2rem;
}

.links a {
  color: var(--text-color);
  font-size: large;
}

.links a:hover {
  color: var(--hover-color);
}

@media (max-width: 1020px) {
  #project {
    padding: 8rem 0;
  }
}

@media (max-width: 1020px) {
  .project-content {
    text-align: center;
  }
}

@media (max-width: 1020px) {
  .projects-grid {
    grid-template-columns: 1fr;
    align-self: center;
  }
}

@media (max-width: 600px) {
  .pro-card {
    width: 350px;
  }
}

@media (max-width: 600px) {
  .pro-img {
    width: 350px;
    height: 250px;
  }
}
