.about {
  background-color: var(--primary-color);
  padding: 15rem 0;
}

.about-content {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
}

.img-side img {
  border-radius: 1.7rem;
  height: 42rem;
  width: 48rem;
}

.text-side {
  padding-right: 1.5rem;
}

.text-side h4 {
  color: var(--hover-color);
  font-size: 1.7rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.text-side h3 {
  color: var(--text-color);
  font-family: "Poppins", sans-serif;
  font-size: 2.5rem;
  line-height: 1.4;
  margin-bottom: 2rem;
}

.text-side p {
  color: var(--secondary-text-color);
  font-family: "Mulish", sans-serif;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 1.5;
}

@media (max-width: 1020px) {
  .about {
    padding: 8rem 0;
  }
}

@media (max-width: 1020px) {
  .about-content {
    grid-template-columns: 1fr;
    text-align: center;
  }
}

@media (max-width: 1020px) {
  .img-side img {
    width: 30rem;
    height: 24rem;
  }
}

@media (max-width: 1020px) {
  .text-side {
    max-width: 54rem;
    margin: 0 auto;
  }
}

@media (max-width: 400px) {
  .text-side {
    padding-right: 0;
  }
}
