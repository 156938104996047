#home {
  width: 100%;
  background-color: var(--secondary-color);
}

.home-content {
  align-items: center;
  display: flex;
  gap: 10rem;
  height: 80rem;
  justify-content: center;
}

.home-text {
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  transition: all 0.2s;
}

.home-text h1 {
  color: var(--text-color);
  font-size: 5.5rem;
  line-height: 1.2;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.home-text p {
  color: var(--secondary-text-color);
  font-family: "Mulish", sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.6;
}

.home-text span {
  cursor: pointer;
  display: flex;
  gap: 1.3rem;
  margin: 2.5rem 0;
}

.home-text span a {
  color: var(--text-color);
  font-size: 3rem;
}

.home-text span a :hover {
  color: var(--hover-color);
}

.home-img {
  background-image: url(../images/Profile.png);
  background-position: 50%;
  height: 40rem;
  width: 35rem;
  background-size: fill;
  background-repeat: no-repeat;
}

@media (max-width: 900px) {
  .home-content {
    flex-direction: column-reverse;
    text-align: center;
    gap: 0;
  }
}

@media (max-width: 500px) {
  .home-text h1 {
    font-size: 4rem;
  }
}

@media (max-width: 1020px) {
  .home-text p {
    margin-bottom: 5rem;
  }
}

@media (max-width: 1020px) {
  .home-text span {
    justify-content: center;
    margin-top: -2rem;
    margin-bottom: 4rem;
    gap: 1.6rem;
  }
}

@media (max-width: 1020px) {
  .home-text span a {
    justify-content: center;
    margin-top: -2rem;
    margin-bottom: 4rem;
    gap: 1.6rem;
  }
}

@media (max-width: 900px) {
  .home-img {
    background-size: cover;
    width: 28rem;
    height: 28rem;
  }
}
