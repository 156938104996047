@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #fff;
  font-family: "Poppins", sans-serif;
}

html {
  font-size: 62.5%;
}

.container {
  margin: 0 auto;
  max-width: 107rem;
  padding: 0 4rem;
}

.light {
  --primary-color: #fff;
  --secondary-color: #f9f9f9;
  --text-color: #2d2e32;
  --secondary-text-color: rgb(55 65 81);
  --hover-color: #147efb;
  --footer-bg: #2d2e32;
}

.dark {
  --primary-color: #1f1f1f;
  --secondary-color: #222222;
  --text-color: #fff;
  --secondary-text-color: #f9f9f9;
  --hover-color: #f56565;
  --footer-bg: #222222;
}
