#contact {
  background-color: var(--primary-color);
  padding: 11rem 0;
}

.contact-title p {
  color: var(--hover-color);
  font-size: 1.7rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.contact-title h3 {
  color: var(--text-color);
  font-size: 2.5rem;
}

.contact-icons {
  display: flex;
  flex-wrap: wrap;
  gap: 8rem;
  margin-top: 6rem;
}

.contact-iconbox {
  align-items: center;
  display: flex;
  gap: 2.5rem;
}

.contact-iconbox span {
  font-size: 3rem;
  color: var(--hover-color);
}

.contact-iconbox span a {
  color: var(--hover-color);
  font-size: 3rem;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.contact-info h3 {
  color: var(--text-color);
  font-size: 1.7rem;
}

.contact-info a,
.contact-info p {
  color: var(--secondary-text-color);
  cursor: pointer;
  font-size: 1.7rem;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.contact-info a:hover,
.contact-info p:hover {
  color: var(--hover-color);
  transition: all 0.3s;
}

@media (max-width: 1020px) {
  .contact {
    padding: 8rem 0;
  }
}

@media (max-width: 1020px) {
  .contact-title {
    text-align: center;
  }
}

@media (max-width: 1020px) {
  .contact-icons {
    flex-direction: column;
    gap: 5rem;
    justify-content: center;
    text-align: center;
  }
}

@media (max-width: 1020px) {
  .contact-iconbox {
    flex-direction: column;
    gap: 1rem;
  }
}
