footer {
  background-color: var(--footer-bg);
  padding: 5rem 0;
  width: 100%;
}

.footerc {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

footer h3 {
  color: #fff;
  font-size: 1.7rem;
}

.footer-socials {
  display: flex;
  gap: 2rem;
}

.footer-socials a {
  color: #fff;
  font-size: 2.3rem;
}

.footer-socials a :hover {
  color: var(--hover-color);
  font-size: 2.3rem;
}

@media (max-width: 550px) {
  .footerc {
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    text-align: center;
  }
}
